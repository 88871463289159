"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapAirbyteSpecToSourceConnectorConfig = exports.makeAirbyteSource = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var types_1 = require("../types");
var makeAirbyteSource = function (airbyteSource) {
    var dockerImageNameWithoutPrefix = airbyteSource.docker_image_name.replace("airbyte/", "");
    return {
        protoType: "airbyte",
        expertMode: false,
        pic: airbyteSource.pic,
        displayName: airbyteSource.displayName,
        id: "airbyte-".concat(dockerImageNameWithoutPrefix),
        collectionTypes: [],
        documentation: airbyteSource.documentation,
        deprecated: airbyteSource.deprecated,
        collectionParameters: [],
        staticStreamsConfigEndpoint: "/airbyte/".concat(dockerImageNameWithoutPrefix, "/catalog"),
        configParameters: [
            {
                displayName: "Airbyte Connector",
                id: "config.docker_image",
                type: types_1.stringType,
                required: true,
                documentation: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Id of Connector Source" }),
                constant: dockerImageNameWithoutPrefix,
            },
        ],
        hasLoadableConfigParameters: true,
    };
};
exports.makeAirbyteSource = makeAirbyteSource;
/**
 * Maps the spec of the Airbyte connector to the Jitsu `Parameter` schema of the `SourceConnector`.
 * @param spec `connectionSpecification` field which is the root of the airbyte source spec.
 */
var mapAirbyteSpecToSourceConnectorConfig = function (spec) {
    return mapAirbyteSpecNode(spec, {
        nodeName: "config",
        parentNode: { id: "config" },
    });
};
exports.mapAirbyteSpecToSourceConnectorConfig = mapAirbyteSpecToSourceConnectorConfig;
var mapAirbyteSpecNode = function mapSpecNode(specNode, options) {
    var _a, _b, _c, _d;
    var result = [];
    var _e = options || {}, nodeName = _e.nodeName, parentNode = _e.parentNode, requiredFields = _e.requiredFields, setChildrenParameters = _e.setChildrenParameters, omitFieldRule = _e.omitFieldRule;
    var id = "".concat(parentNode.id, ".").concat(nodeName);
    var displayName = (_a = specNode["title"]) !== null && _a !== void 0 ? _a : nodeName;
    var required = !!(requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.includes(nodeName || ""));
    var description = specNode["description"];
    var documentation = specNode["description"] ? ((0, jsx_runtime_1.jsx)("span", { dangerouslySetInnerHTML: { __html: specNode["description"] } })) : undefined;
    switch (specNode["type"]) {
        case "array":
            return [
                __assign({ displayName: displayName, id: id, type: (0, types_1.arrayOf)({ typeName: (_c = (_b = specNode["items"]) === null || _b === void 0 ? void 0 : _b.type) !== null && _c !== void 0 ? _c : "string" }), required: required, documentation: documentation, omitFieldRule: omitFieldRule }, setChildrenParameters),
            ];
        case "string": {
            var pattern = specNode["pattern"];
            var now = new Date();
            var startOfPrevMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
            var defaultValue = undefined;
            if (specNode["default"] !== undefined) {
                defaultValue = specNode["default"];
            }
            else if (pattern === "^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z$" ||
                (description === null || description === void 0 ? void 0 : description.includes("YYYY-MM-DDT00:00:00Z"))) {
                // defaultValue = moment().add(-1, "months").startOf("month").format("YYYY-MM-DDT00:00:00") + "Z"
                defaultValue = startOfPrevMonth.toISOString().split(".")[0] + "Z";
            }
            else if (pattern === "^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$" ||
                (description === null || description === void 0 ? void 0 : description.includes("YYYY-MM-DDT00:00:00.000Z"))) {
                defaultValue = startOfPrevMonth.toISOString();
            }
            else if (pattern === "^[0-9]{4}-[0-9]{2}-[0-9]{2}$" || /YYYY-MM-DD[^T]/.test(description)) {
                // defaultValue = moment().add(-1, "months").startOf("month").format("YYYY-MM-DD")
                defaultValue = startOfPrevMonth.toISOString().split("T")[0];
            }
            var isMultiline = !!specNode["multiline"];
            var isSecret = !!specNode["airbyte_secret"];
            var isSelection = !!specNode["enum"];
            var isBackendStoredOauth = !!specNode["env_name"];
            var fieldType = isBackendStoredOauth
                ? types_1.oauthSecretType
                : isMultiline
                    ? (0, types_1.makeStringType)({ multiline: true })
                    : isSecret
                        ? types_1.passwordType
                        : isSelection
                            ? (0, types_1.singleSelectionType)(specNode["enum"])
                            : (0, types_1.makeStringType)(pattern ? { pattern: pattern } : {});
            var mappedStringField = __assign({ displayName: displayName, id: id, type: fieldType, required: required, documentation: documentation, omitFieldRule: omitFieldRule }, setChildrenParameters);
            if (defaultValue) {
                mappedStringField.defaultValue = defaultValue;
            }
            return [mappedStringField];
        }
        case "integer": {
            var mappedIntegerField = {
                displayName: displayName,
                id: id,
                type: (0, types_1.makeIntType)({
                    minimum: specNode["minimum"],
                    maximum: specNode["maximum"],
                }),
                required: required,
                documentation: documentation,
                omitFieldRule: omitFieldRule,
            };
            if (specNode["default"] !== undefined)
                mappedIntegerField.defaultValue = specNode["default"];
            return [mappedIntegerField];
        }
        case "boolean": {
            var mappedBooleanField = {
                displayName: displayName,
                id: id,
                type: types_1.booleanType,
                required: required,
                documentation: documentation,
                omitFieldRule: omitFieldRule,
            };
            if (specNode["default"] !== undefined)
                mappedBooleanField.defaultValue = specNode["default"];
            return [mappedBooleanField];
        }
        case "object": {
            var optionsEntries = [];
            var listOfRequiredFields_1 = [];
            if (specNode["properties"]) {
                optionsEntries = getEntriesFromPropertiesField(specNode);
                var _listOfRequiredFields = specNode["required"] || [];
                listOfRequiredFields_1 = _listOfRequiredFields;
            }
            else if (specNode["oneOf"]) {
                // this is a rare case, see the Postgres source spec for an example
                var name_1 = (_d = specNode["title"]) !== null && _d !== void 0 ? _d : nodeName;
                optionsEntries = getEntriesFromOneOfField(specNode, nodeName);
                var optionsFieldName_1 = Object.entries(optionsEntries[0][1]["properties"]).find(function (_a) {
                    var _b;
                    var fieldName = _a[0], fieldNode = _a[1];
                    return !!fieldNode["const"] || ((_b = fieldNode["enum"]) === null || _b === void 0 ? void 0 : _b.length) == 1;
                })[0];
                var options_1 = optionsEntries.map(function (_a) {
                    var _b, _c, _d, _e;
                    var _ = _a[0], childNode = _a[1];
                    return ((_c = (_b = childNode["properties"]) === null || _b === void 0 ? void 0 : _b[optionsFieldName_1]) === null || _c === void 0 ? void 0 : _c["const"]) ||
                        ((_e = (_d = childNode["properties"]) === null || _d === void 0 ? void 0 : _d[optionsFieldName_1]) === null || _e === void 0 ? void 0 : _e["enum"][0]);
                });
                var defaultAuthOption = options_1.find(function (option) { var _a; return (_a = option.toLowerCase) === null || _a === void 0 ? void 0 : _a.call(option).includes("oauth"); });
                var defaultOption = defaultAuthOption !== null && defaultAuthOption !== void 0 ? defaultAuthOption : options_1[0];
                var mappedSelectionField = {
                    displayName: name_1,
                    id: "".concat(parentNode.id, ".").concat(nodeName, ".").concat(optionsFieldName_1),
                    type: (0, types_1.singleSelectionType)(options_1),
                    defaultValue: defaultOption,
                    required: required,
                    documentation: documentation,
                    omitFieldRule: omitFieldRule,
                };
                mappedSelectionField.defaultValue = (specNode === null || specNode === void 0 ? void 0 : specNode["default"]) || options_1[0];
                result.push(mappedSelectionField);
            }
            else {
                throw new Error("Failed to parse Airbyte source spec -- unknown field of `object` type");
            }
            var parentId_1 = id;
            optionsEntries.forEach(function (_a) {
                var nodeName = _a[0], node = _a[1];
                return result.push.apply(result, mapSpecNode(node, {
                    nodeName: nodeName,
                    requiredFields: listOfRequiredFields_1,
                    parentNode: __assign(__assign({}, specNode), { id: parentId_1, parentNode: parentNode }),
                }));
            });
            break;
        }
        case undefined: {
            if (specNode["allOf"]) {
                // Case for the nodes that have the 'allOf' property
                var nodes = specNode["allOf"];
                nodes.forEach(function (node) {
                    result.push.apply(result, mapSpecNode(node, {
                        nodeName: nodeName,
                        requiredFields: requiredFields,
                        parentNode: __assign(__assign({}, node), { id: parentNode.id, parentNode: parentNode }),
                        setChildrenParameters: {
                            documentation: documentation,
                            required: required,
                        },
                    }));
                });
            }
            else if (specNode["$ref"]) {
                var refNode = getAirbyteSpecNodeByRef(parentNode, specNode["$ref"]);
                result.push.apply(result, mapSpecNode(refNode, {
                    nodeName: nodeName,
                    parentNode: parentNode,
                    setChildrenParameters: setChildrenParameters,
                }));
            }
            else if (isSubNodeOf_oneOf(specNode)) {
                // Special case for the nodes from the `oneOf` list in the `object` node
                var childrenNodesEntries = Object.entries(specNode["properties"]).sort(function (_a, _b) {
                    var _ = _a[0], nodeA = _a[1];
                    var __ = _b[0], nodeB = _b[1];
                    return (nodeA === null || nodeA === void 0 ? void 0 : nodeA["order"]) - (nodeB === null || nodeB === void 0 ? void 0 : nodeB["order"]);
                });
                var _f = childrenNodesEntries.find(function (_a) {
                    var _b;
                    var _ = _a[0], node = _a[1];
                    return !!node["const"] || ((_b = node["enum"]) === null || _b === void 0 ? void 0 : _b.length) == 1;
                }), parentNodeValueProperty = _f[0], selectValueNode_1 = _f[1];
                var parentNodeValueKey_1 = "".concat(parentNode.id, ".").concat(parentNodeValueProperty);
                var _listOfRequiredFields_1 = specNode["required"] || [];
                childrenNodesEntries
                    .filter(function (_a) {
                    var _b;
                    var _ = _a[0], node = _a[1];
                    return !node["const"] && ((_b = node["enum"]) === null || _b === void 0 ? void 0 : _b.length) != 1;
                }) // Excludes the entry with the select option value
                    .forEach(function (_a) {
                    var nodeName = _a[0], node = _a[1];
                    return result.push.apply(result, mapSpecNode(node, {
                        nodeName: nodeName,
                        requiredFields: _listOfRequiredFields_1,
                        parentNode: __assign(__assign({}, specNode), { id: parentNode.id, parentNode: parentNode }),
                        omitFieldRule: function (config) {
                            var _a;
                            var parentSelectionNodeValue = parentNodeValueKey_1
                                .split(".")
                                .reduce(function (obj, key) { return obj[key] || {}; }, config);
                            var showChildFieldIfThisParentValueSelected = (selectValueNode_1 === null || selectValueNode_1 === void 0 ? void 0 : selectValueNode_1["const"]) || ((_a = selectValueNode_1 === null || selectValueNode_1 === void 0 ? void 0 : selectValueNode_1["enum"]) === null || _a === void 0 ? void 0 : _a[0]);
                            return parentSelectionNodeValue !== showChildFieldIfThisParentValueSelected;
                        },
                    }));
                });
            }
            break;
        }
    }
    return result;
};
var getAirbyteSpecNodeByRef = function (parentNode, ref) {
    var rootNode = getAirbyteSpecRootNode(parentNode);
    var nodesNames = ref.replace("#/", "").split("/");
    return nodesNames.reduce(function (parentNode, childNodeName) {
        if (parentNode === null)
            return null;
        var childNode = parentNode[childNodeName];
        try {
            return childNode;
        }
        catch (_a) {
            return null;
        }
    }, rootNode);
};
var getAirbyteSpecRootNode = function (node) {
    var _a;
    var grandparent = (_a = node === null || node === void 0 ? void 0 : node.parentNode) === null || _a === void 0 ? void 0 : _a.parentNode;
    if (!grandparent)
        return node;
    return getAirbyteSpecRootNode(node.parentNode);
};
var getEntriesFromPropertiesField = function (node) {
    var _a;
    var subNodes = node["properties"];
    var entries = Object.entries(subNodes);
    var isOrdered = (_a = entries[0][1]) === null || _a === void 0 ? void 0 : _a["order"];
    if (isOrdered)
        entries = entries.sort(function (a, b) { var _a, _b; return +((_a = a[1]) === null || _a === void 0 ? void 0 : _a["order"]) - +((_b = b[1]) === null || _b === void 0 ? void 0 : _b["order"]); });
    return entries;
};
var getEntriesFromOneOfField = function (node, nodeName) {
    var subNodes = node["oneOf"];
    return Object.entries(subNodes).map(function (_a) {
        var idx = _a[0], subNode = _a[1];
        /**
         * Set subNode type to undefined so that the algorithm further
         * recognise the node as the `oneOf` node. Refer to `isSubNodeOf_oneOf` for implementation.
         */
        var newSubNode = __assign(__assign({}, subNode), { type: undefined });
        return ["".concat(nodeName, "-option-").concat(idx), newSubNode];
    });
};
var isSubNodeOf_oneOf = function (node) { return node.type === undefined; };
